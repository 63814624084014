import React from "react"

const Logo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="26.005">
            <path fill="#f58220" d="M19.323 18.918h.009c1.844-.462 3.626-1.014 5.367-1.6a32.406 32.406 0 0021.663 8.116 34.718 34.718 0 01-27.13-6.49c.029-.005.061-.011.092-.019m22.926-.021a5.641 5.641 0 006.316.124 5.1 5.1 0 002.275-5.24 4.635 4.635 0 00-3.574-3.661 28.136 28.136 0 015.859-.137 8.033 8.033 0 01.952 3.456 7.83 7.83 0 01-4.479 7.49 7.558 7.558 0 01-4.278.622 7.213 7.213 0 01-3.756-1.814 6.814 6.814 0 01-1.812-7.306 6.275 6.275 0 002.496 6.458zm23.4-11.278a27.458 27.458 0 00-10.682-6.242A29.131 29.131 0 0042.506.233a39.945 39.945 0 00-11.907 3.74c-3.753 1.761-7.274 3.889-10.818 5.983 3.813-1.563 7.529-3.34 11.3-4.888a46.527 46.527 0 0111.62-3.316 31.584 31.584 0 0111.939.777 33.839 33.839 0 0111.005 5.09zm4.355 6.509a26.619 26.619 0 00-16-10.415 29.638 29.638 0 00-10.019-.5 40.993 40.993 0 00-4.882.9c-1.593.4-3.135.9-4.657 1.411-6.072 2.136-11.476 5.076-16.976 7.325S6.317 16.897.003 16.68a20.241 20.241 0 004.533 1.51 29.421 29.421 0 004.828.5 39.231 39.231 0 009.6-1.246c6.256-1.566 11.941-4.174 17.523-6.13 5.591-1.991 11.086-3.328 16.779-2.844A36.789 36.789 0 0170 14.128z"/>
        </svg>
    )
};

export default Logo;