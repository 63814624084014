import React from "react"
import Logo from "./logo"

class Footer extends React.Component {
    render() {
        return (
            <footer className="bg-gray-100 py-6">

                <div className="mx-auto container">
                    <Logo />
                </div>

                <div className="mx-auto container flex flex-wrap justify-between py-6">
                    <div className="w-full sm:w-1/2 md:w-1/4">
                        <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">LOKALIZACJA</span>
                        <p className="text-gray-700">Adres: Mickiewicza 15 <span className="text-gray-600">(2 piętro)</span></p>
                        <p className="text-gray-700">Miejscowość: Sandomierz</p>
                        <p className="text-gray-700">Kod pocztowy: 27-600</p>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
                        <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">GODZINY OTWARCIA</span>
                        <p className="text-gray-700">Pon-pt: <span className="font-semibold">8:00-18:00</span></p>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
                        <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">KONTAKT</span>
                        <p className="text-gray-700">Telefon: <a href="tel:158329535" className="underline font-semibold">15 832 95 35</a></p>
                        <p className="text-gray-700">Email: <a href="mailto:recepcja@lens-med.pl" className="underline font-semibold">recepcja@lens-med.pl</a></p>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
                        <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">Dane</span>
                        <p className="text-gray-700">Nazwa: <span className="font-semibold">LENSMED sp. z o.o</span></p>
                        <p className="text-gray-700">REGON: <span className="font-semibold">260594908</span></p>
                        <p className="text-gray-700">NIP: <span className="font-semibold">8641949222</span></p>
                        <p className="text-gray-700">KRS: <span className="font-semibold">0000415441</span></p>
                    </div>
                </div>

                <div className="mx-auto container">
                    <div className="w-full border-b border-gray-300"></div>
                </div>

                <div className="container mx-auto flex items-center justify-between py-6">
                    <div className="">
                        © 2020 LENSMED. Wszelkie prawa zastrzeżone.
                    </div>
                    <div>
                        <div className="flex justify-between sm:justify-start mt-4">
                            <div><a href="https://www.facebook.com/CentrumLensmed/" title="Link do naszego Facebooka"
                                    className="mr-8 inline-block hover:text-indigo-800" rel="noopener noreferrer">
                                <svg className="h-5 w-5 fill-current text-gray-700" height="512pt" viewBox="0 0 512 512"
                                     width="512pt" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="m297.277344 508.667969c-2.132813.347656-4.273438.667969-6.421875.960937 2.148437-.292968 4.289062-.613281 6.421875-.960937zm0 0"></path>
                                    <path
                                        d="m302.398438 507.792969c-1.019532.1875-2.039063.359375-3.058594.535156 1.019531-.175781 2.039062-.347656 3.058594-.535156zm0 0"></path>
                                    <path
                                        d="m285.136719 510.339844c-2.496094.28125-5.007813.53125-7.527344.742187 2.519531-.210937 5.03125-.460937 7.527344-.742187zm0 0"></path>
                                    <path
                                        d="m290.054688 509.738281c-1.199219.160157-2.40625.308594-3.609376.449219 1.203126-.140625 2.410157-.289062 3.609376-.449219zm0 0"></path>
                                    <path
                                        d="m309.367188 506.410156c-.898438.191406-1.800782.382813-2.703126.566406.902344-.183593 1.804688-.375 2.703126-.566406zm0 0"></path>
                                    <path
                                        d="m326.664062 502.113281c-.726562.207031-1.453124.402344-2.179687.605469.726563-.203125 1.453125-.398438 2.179687-.605469zm0 0"></path>
                                    <path
                                        d="m321.433594 503.542969c-.789063.207031-1.582032.417969-2.375.617187.792968-.199218 1.585937-.40625 2.375-.617187zm0 0"></path>
                                    <path
                                        d="m314.589844 505.253906c-.835938.195313-1.679688.378906-2.523438.566406.84375-.1875 1.6875-.371093 2.523438-.566406zm0 0"></path>
                                    <path
                                        d="m277.527344 511.089844c-1.347656.113281-2.695313.214844-4.046875.304687 1.351562-.089843 2.699219-.191406 4.046875-.304687zm0 0"></path>
                                    <path
                                        d="m512 256c0-141.363281-114.636719-256-256-256s-256 114.636719-256 256 114.636719 256 256 256c1.503906 0 3-.03125 4.5-.058594v-199.285156h-55v-64.097656h55v-47.167969c0-54.703125 33.394531-84.476563 82.191406-84.476563 23.367188 0 43.453125 1.742188 49.308594 2.519532v57.171875h-33.648438c-26.546874 0-31.6875 12.617187-31.6875 31.128906v40.824219h63.476563l-8.273437 64.097656h-55.203126v189.453125c107.003907-30.675781 185.335938-129.257813 185.335938-246.109375zm0 0"></path>
                                    <path
                                        d="m272.914062 511.429688c-2.664062.171874-5.339843.308593-8.023437.398437 2.683594-.089844 5.359375-.226563 8.023437-.398437zm0 0"></path>
                                    <path
                                        d="m264.753906 511.835938c-1.414062.046874-2.832031.082031-4.25.105468 1.417969-.023437 2.835938-.058594 4.25-.105468zm0 0"></path>
                                </svg>
                            </a></div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;