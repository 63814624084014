import React, {useState} from "react"
import Logo from "./logo"
import { Link } from 'gatsby';

const Header = () => {
    const [isOpened, open] = useState(false);
      return (
          <>
              <header className="fixed w-full z-10 bg-white">
                  <div className="shadow py-4 md:py-0">
                      <div className="container mx-auto">
                          <div className="bg-white md:flex sm:justify-between md:items-center">
                              <div className="flex items-center justify-between">
                                  <div>
                                      <Link to="/" title="Strona główna">
                                          <Logo />
                                      </Link>
                                  </div>
                                  <div className="md:hidden">
                                      <button title="Otwórz/zamknij menu" onClick={() => open(! isOpened)} type="button" className="block text-black focus:text-black focus:outline-none">
                                          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                              { isOpened && (
                                                  <path d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                                              )}
                                              { ! isOpened && (
                                                  <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                                              )}
                                          </svg>
                                      </button>
                                  </div>
                              </div>
                              <nav className={'md:flex uppercase text-gray-800 font-semibold tracking-wider  ' + (isOpened ? 'block' : 'hidden')}>
                                  <ul className="md:flex">
                                      <li>
                                          <Link to="/" activeClassName="link-active" className="menu-item md:border-r border-gray-200 tracking-wide">Główna</Link>
                                      </li>
                                      <li>
                                          <Link to="/aktualnosci/" partiallyActive={true} activeClassName="link-active" className="menu-item md:border-r border-gray-200 tracking-wide">Aktualności</Link>
                                      </li>
                                      {/*<li>*/}
                                      {/*    <Link to="/o-firmie/" activeClassName="link-active" className="menu-item md:border-r border-gray-200 tracking-wide">O firmie</Link>*/}
                                      {/*</li>*/}
                                      <li>
                                          <Link to="/lekarze/" partiallyActive={true} activeClassName="link-active" className="menu-item md:border-r border-gray-200 tracking-wide">Lekarze</Link>
                                      </li>
                                      <Link to="/kontakt/" className="flex border-gray-900 border-2 font-semibold rounded py-1 px-4 md:hidden justify-around">
                                          <div className="flex items-center">
                                              <svg className="fill-current mr-2 inline-block" xmlns="http://www.w3.org/2000/svg" width="12" height="12.001">
                                                  <path d="M11.974 9.3q-.051-.153-.648-.452-.162-.094-.46-.256t-.541-.3q-.243-.136-.456-.264-.034-.028-.213-.149a2.4 2.4 0 00-.3-.183.558.558 0 00-.243-.06.707.707 0 00-.426.243 4.15 4.15 0 00-.469.529 5.139 5.139 0 01-.452.528.65.65 0 01-.392.243.58.58 0 01-.192-.043c-.077-.028-.135-.053-.175-.072s-.108-.06-.2-.12-.151-.092-.162-.1a8.957 8.957 0 01-2-1.483 8.952 8.952 0 01-1.483-2q-.009-.017-.1-.162t-.119-.2a1.847 1.847 0 01-.072-.175.577.577 0 01-.043-.192.65.65 0 01.243-.392 5.133 5.133 0 01.519-.456 4.148 4.148 0 00.528-.469.707.707 0 00.243-.426.557.557 0 00-.06-.243 2.369 2.369 0 00-.183-.3q-.124-.179-.149-.213-.128-.213-.264-.456t-.3-.541q-.162-.3-.256-.46-.3-.6-.452-.648A.477.477 0 002.523 0a2.808 2.808 0 00-.6.085 3.375 3.375 0 00-.584.179 2.285 2.285 0 00-.9 1.04A3.3 3.3 0 000 2.889a3.291 3.291 0 00.03.447 3.677 3.677 0 00.107.49q.077.273.124.4t.174.474q.128.341.153.418a7.72 7.72 0 00.707 1.492 12.637 12.637 0 001.837 2.258A12.643 12.643 0 005.39 10.7a7.732 7.732 0 001.492.707q.077.025.418.153t.473.175q.132.047.4.124a3.71 3.71 0 00.49.107 3.162 3.162 0 002.033-.4 2.283 2.283 0 001.04-.9 3.357 3.357 0 00.179-.584 2.8 2.8 0 00.085-.6.475.475 0 00-.026-.182z" data-name="Path 942"/>
                                              </svg>
                                              <span className="text-black">Kontakt</span>
                                          </div>
                                      </Link>
                                  </ul>
                              </nav>
                              <div>
                                  <Link to="/kontakt/" className="hidden btn-main font-semibold rounded-full py-1 px-4 md:flex items-center">
                                      <svg className="fill-current mr-2 inline-block" xmlns="http://www.w3.org/2000/svg" width="12" height="12.001">
                                          <path d="M11.974 9.3q-.051-.153-.648-.452-.162-.094-.46-.256t-.541-.3q-.243-.136-.456-.264-.034-.028-.213-.149a2.4 2.4 0 00-.3-.183.558.558 0 00-.243-.06.707.707 0 00-.426.243 4.15 4.15 0 00-.469.529 5.139 5.139 0 01-.452.528.65.65 0 01-.392.243.58.58 0 01-.192-.043c-.077-.028-.135-.053-.175-.072s-.108-.06-.2-.12-.151-.092-.162-.1a8.957 8.957 0 01-2-1.483 8.952 8.952 0 01-1.483-2q-.009-.017-.1-.162t-.119-.2a1.847 1.847 0 01-.072-.175.577.577 0 01-.043-.192.65.65 0 01.243-.392 5.133 5.133 0 01.519-.456 4.148 4.148 0 00.528-.469.707.707 0 00.243-.426.557.557 0 00-.06-.243 2.369 2.369 0 00-.183-.3q-.124-.179-.149-.213-.128-.213-.264-.456t-.3-.541q-.162-.3-.256-.46-.3-.6-.452-.648A.477.477 0 002.523 0a2.808 2.808 0 00-.6.085 3.375 3.375 0 00-.584.179 2.285 2.285 0 00-.9 1.04A3.3 3.3 0 000 2.889a3.291 3.291 0 00.03.447 3.677 3.677 0 00.107.49q.077.273.124.4t.174.474q.128.341.153.418a7.72 7.72 0 00.707 1.492 12.637 12.637 0 001.837 2.258A12.643 12.643 0 005.39 10.7a7.732 7.732 0 001.492.707q.077.025.418.153t.473.175q.132.047.4.124a3.71 3.71 0 00.49.107 3.162 3.162 0 002.033-.4 2.283 2.283 0 001.04-.9 3.357 3.357 0 00.179-.584 2.8 2.8 0 00.085-.6.475.475 0 00-.026-.182z" data-name="Path 942"/>
                                      </svg>
                                      <span>Kontakt</span>
                                  </Link>
                              </div>
                          </div>
                      </div>
              </div>
          </header>
          </>
      )
};

export default Header;