import React from "react"
import Header from './header';
import Footer from './footer';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div>
        <Header />
          <main className="pt-12 md:pt-16">
            {children}
          </main>
        <Footer />
      </div>
    )
  }
}

export default Layout
